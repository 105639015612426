/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type GetBankTransfersMethodsItem = typeof GetBankTransfersMethodsItem[keyof typeof GetBankTransfersMethodsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBankTransfersMethodsItem = {
  ach: 'ach',
  sameday_ach: 'sameday_ach',
  instant: 'instant',
} as const;
