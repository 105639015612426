/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

/**
 * 
checking ExternalBankAccountTypeChecking
savings ExternalBankAccountTypeSavings
 */
export type CreateRecipientWithBankAccountParamsAccountType = typeof CreateRecipientWithBankAccountParamsAccountType[keyof typeof CreateRecipientWithBankAccountParamsAccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateRecipientWithBankAccountParamsAccountType = {
  checking: 'checking',
  savings: 'savings',
} as const;
