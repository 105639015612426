/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type PrimaryAuthorizedPersonTitle = typeof PrimaryAuthorizedPersonTitle[keyof typeof PrimaryAuthorizedPersonTitle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrimaryAuthorizedPersonTitle = {
  PRESIDENT: 'PRESIDENT',
  VICE_PRESIDENT: 'VICE_PRESIDENT',
  TREASURER: 'TREASURER',
  OWNER: 'OWNER',
  CEO: 'CEO',
  CFO: 'CFO',
  COO: 'COO',
} as const;
