import { ref, Ref } from 'vue';
import useHostname from './useHostname';

export default function useIsPaybiltDomain(): { isPaybiltDomain: Ref<boolean> } {
  const isPaybiltDomain = ref(false);
  const hostname = useHostname();

  if (hostname.includes('cards.paybilt.com')) {
    isPaybiltDomain.value = true;
  }

  return {
    isPaybiltDomain,
  };
}
