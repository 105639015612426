
import { defineComponent } from 'vue';
import LanguageOptions from '@/components/LanguageOptions.vue';

export default defineComponent({
  components: {
    LanguageOptions,
  },

});
