
import { defineComponent } from 'vue';

export default defineComponent({
  watch: {
    '$i18n.locale': {
      handler(newLocale: string) {
        // only updating the route if the current route is cardholders
        if (newLocale !== this.$route.query.lang && this.$route.name === 'cardholders') {
          this.$router.replace({ query: { ...this.$route.query, lang: newLocale } });
        }
      },
    },
  },
});

