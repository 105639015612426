/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type OrderAndShipCardsParamsShipmentMethod = typeof OrderAndShipCardsParamsShipmentMethod[keyof typeof OrderAndShipCardsParamsShipmentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderAndShipCardsParamsShipmentMethod = {
  USPS_GROUND: 'USPS_GROUND',
  USPS_PRIORITY: 'USPS_PRIORITY',
  UPS_NEXT_DAY: 'UPS_NEXT_DAY',
  UPS_SECOND_DAY: 'UPS_SECOND_DAY',
} as const;
