import Axios from 'axios';
import type { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import { getAuth } from 'firebase/auth';

export type AxiosInstanceReplacedListener = (instance: AxiosInstance) => void;

export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.VUE_APP_WHIMSY_BASE_URL });

AXIOS_INSTANCE.interceptors.request.use(async (config: AxiosRequestConfig) => {
  try {
    const user = getAuth().currentUser;
    if (user) {
      const token = await user.getIdToken();

      // eslint-disable-next-line no-param-reassign
      config.headers = config.headers || {};
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (error) {
    console.error('Error adding Authorization header:', error);
    return config;
  }
});

export const client = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token })
    .then(({ data }) => data)
    .catch((e: AxiosError | Error) => {
      if ('isAxiosError' in e && e.isAxiosError && e.response?.data) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw Object.assign(e, e.response.data);
      }
      throw e;
    });

  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

  declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Promise<T> {
      cancel?(): void;
    }
  }

export type ErrorType<ServerError> = AxiosError<void> & (ServerError | void);

export default client;
