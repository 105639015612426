/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type InitiateBankAccountTransfersParamsSpeed = typeof InitiateBankAccountTransfersParamsSpeed[keyof typeof InitiateBankAccountTransfersParamsSpeed];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InitiateBankAccountTransfersParamsSpeed = {
  ach: 'ach',
  sameday_ach: 'sameday_ach',
  instant: 'instant',
} as const;
