/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Whimsy APIs
 * Whimsy API specification
 * OpenAPI spec version: 1.0.0
 */

export type CreatePrimaryAuthorizedPersonParamsTitle = typeof CreatePrimaryAuthorizedPersonParamsTitle[keyof typeof CreatePrimaryAuthorizedPersonParamsTitle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreatePrimaryAuthorizedPersonParamsTitle = {
  PRESIDENT: 'PRESIDENT',
  VICE_PRESIDENT: 'VICE_PRESIDENT',
  TREASURER: 'TREASURER',
  OWNER: 'OWNER',
  CEO: 'CEO',
  CFO: 'CFO',
  COO: 'COO',
} as const;
