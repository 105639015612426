/* eslint-disable no-shadow */
enum SupportedLanguages {
    ENGLISH = 'en',
    SPANISH = 'es',
    RUSSIAN = 'ru',
    CHINESE = 'zh',
    UKRAINIAN = 'uk',
    FRENCH = 'fr',
    SWAHILI = 'sw',
    DARI = 'da',
    PASHTO = 'pa',
    FARSI = 'far',
    ARABIC = 'ar'
}

export default SupportedLanguages;
