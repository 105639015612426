/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type BusinessProductType = typeof BusinessProductType[keyof typeof BusinessProductType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessProductType = {
  cards: 'cards',
  bank_transfers: 'bank_transfers',
} as const;
