import { createApp } from 'vue';
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import * as FullStory from '@fullstory/browser';
// eslint-disable-next-line import/no-extraneous-dependencies
import Vue3Lottie from 'vue3-lottie';
import { VueQueryPlugin } from 'vue-query';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from './store/store';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Full Story configuration
FullStory.init({ orgId: 'o-1EHP9C-na1' });

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence);

const appToMount = createApp(App);
appToMount.config.globalProperties.$FullStory = FullStory;
appToMount.use(i18n);
appToMount.use(store);
appToMount.use(Vue3Lottie);
appToMount.use(router).use(VueQueryPlugin).mount('#app');
