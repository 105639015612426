/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type GetBankTransfersStatusesItem = typeof GetBankTransfersStatusesItem[keyof typeof GetBankTransfersStatusesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBankTransfersStatusesItem = {
  created: 'created',
  processing: 'processing',
  completed: 'completed',
  failed: 'failed',
} as const;
