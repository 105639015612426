/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type CreateBusinessParamsType = typeof CreateBusinessParamsType[keyof typeof CreateBusinessParamsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateBusinessParamsType = {
  CORPORATION: 'CORPORATION',
  PARTNERSHIP: 'PARTNERSHIP',
  SOLE_PROPRIETORSHIP: 'SOLE_PROPRIETORSHIP',
  NON_PROFIT: 'NON_PROFIT',
  LLC: 'LLC',
} as const;
